import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject, Subscription, map, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { DistrictSimpleDto } from '../../../../common/dtos/district.dto';
import { getIdsFromDefaultItemsForSelect } from '../../helpers/select.utilities';
import { DistrictSearchService } from '../../services/district-search/district-search.service';

@Component({
  selector: 'app-district-select',
  templateUrl: './district-select.component.html',
  styleUrls: ['./district-select.component.scss'],
})
export class DistrictSelectComponent implements OnInit, OnChanges {
  @ViewChild('select') select: NgSelectComponent;

  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  districtList: Observable<DistrictSimpleDto[]>;

  districtLibrary: DistrictSimpleDto[] = [];

  searchInput$ = new Subject<string>();

  searchSubscription: Subscription | null;

  searchLoading = false;

  labelId = uuidv4();

  internalSelectedDistrictList: DistrictSimpleDto[] = [];

  internalSelectedDistrict: DistrictSimpleDto | null;

  isEventInternal = false; // Flag to track internal operations

  @Input() simpleSelect = false;

  @Input() clearAfterSelection = false;

  @Input() label = 'Search for and select a district';

  @Input() placeholder = 'Search for a district...';

  @Input() isMulti = false;

  @Input() clearable = false;

  @Input() clearIndex = 0;

  @Input() defaultDistricts: number[] | DistrictSimpleDto[] | null;

  @Output() readonly selectedDistrictList: EventEmitter<DistrictSimpleDto[]> =
    new EventEmitter();

  @Output() readonly selectedDistrict: EventEmitter<DistrictSimpleDto | null> =
    new EventEmitter();

  @Output() readonly districtsLoaded: EventEmitter<boolean> =
    new EventEmitter();

  constructor(private districtSearch: DistrictSearchService) {}

  ngOnInit(): void {
    if (this.simpleSelect) {
      this.districtSearch.getAllDistricts().subscribe((districts) => {
        this.districtLibrary = [...districts];
        this.districtList = of(this.districtLibrary);
      });
    } else {
      this.districtList = this.districtSearch.items.pipe(
        map((districts) => {
          this.districtLibrary = [...this.districtLibrary, ...districts];
          this.searchLoading = false;
          return districts;
        })
      );
      this.searchInput$.subscribe((term) => {
        if (term) {
          this.searchLoading = true;
        }
        return this.districtSearch.search(term);
      });
      this.searchInput$.next('');
    }
    this.loadDefaultDistricts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clearIndex'] && this.clearIndex) {
      this.ngSelectComponent.handleClearClick();
    }
  }

  loadDefaultDistricts() {
    if (this.defaultDistricts) {
      const districtIds = getIdsFromDefaultItemsForSelect(
        this.defaultDistricts
      );
      let districtIdTracker = districtIds.length - 1;
      districtIds.forEach((districtId) => {
        this.districtSearch
          .getDistrict(districtId as number)
          .subscribe((district) => {
            if (district) {
              if (this.isMulti) {
                this.internalSelectedDistrictList.push(district);
                this.internalSelectedDistrictList.sort((a, b) =>
                  a.title.localeCompare(b.title)
                );
              } else {
                this.internalSelectedDistrict = district;
              }
              if (!districtIdTracker) {
                this.districtsLoaded.emit(true);
              }
              districtIdTracker -= 1;
            }
          });
      });
    }
  }

  districtSelected(district: DistrictSimpleDto) {
    if (this.isEventInternal) {
      return;
    }
    if (this.isMulti && district !== undefined) {
      if (
        !this.internalSelectedDistrictList.some(
          (item) => item.id === district.id
        )
      ) {
        this.isEventInternal = true; // Suppress additional triggers
        this.internalSelectedDistrictList.push(district);
        this.internalSelectedDistrictList.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
        this.select.handleClearClick();
        this.isEventInternal = false; // Re-enable after operations
      }
      this.select.handleClearClick();
    } else {
      this.internalSelectedDistrict = district;
    }
    this.outputDistricts();
    if (this.clearAfterSelection) {
      // Without a timeout you get infinite recursion
      setTimeout(() => {
        this.select.unselect(this.select.selectedItems[0]);
      });
    }
  }

  outputDistricts() {
    if (this.isMulti) {
      this.selectedDistrictList.emit(this.internalSelectedDistrictList);
    } else {
      this.selectedDistrict.emit(this.internalSelectedDistrict);
    }
  }

  removeDistrictFromList(district: DistrictSimpleDto) {
    this.internalSelectedDistrictList =
      this.internalSelectedDistrictList.filter(
        (item) => item.id !== district.id
      );
    this.outputDistricts();
  }

  reset() {
    if (this.isMulti) {
      this.internalSelectedDistrictList = [];
    } else {
      this.internalSelectedDistrict = null;
    }
    this.select.selectedItems.forEach((item) => {
      this.select.unselect(item);
    });
  }
}
